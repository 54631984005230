import api from "app/api";

export interface BookingOption {
  id: number;
  name: string;
  qty: number;
  option_group_id: number;
  option_group_name: string;
}

export interface Booking {
  id: number;
  program_variant_id: number;
  program_id: number;
  start_time: string;
  boat_id: number;
  boat_name: string;
  number_of_adults: number | null;
  number_of_kids: number | null;
  number_of_babies: number | null;
  number_of_guides: number | null;
  name: string;
  description: string | null;
  variant_name: string | null;
  table_numbers: string[] | null;
  booking_options: BookingOption[];
}

export interface Summary {
  name: string;
  qty: number;
  type: "option" | "variant";
  item_name?: string;
  id: number;
}

export interface KitchenItem {
  start_time: string;
  boat_id: number;
  boat_name: string;
  program_ids: number[];
  summary: Summary[];
  bookings: Booking[];
}

export function getKitchenData(
  startDate: string | undefined,
  endDate: string | undefined,
  boatIds: number[] | null,
  programIds: number[] | null
) {
  return api.get<KitchenItem[]>("/kitchen", {
    params: {
      from: startDate,
      to: endDate,
      boatIds,
      programIds,
    },
  });
}
