import LayersClearIcon from "@mui/icons-material/LayersClear";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import api from "app/api";
import { Button, DialogTitle, globalMessageHandler, Spacer } from "common";
import { BookingItem } from "features/bookings/bookingApi";
import { BOOKING_STATUSES } from "features/bookings/bookingConstants";
import I18n from "i18n";
import moment from "moment";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ScheduleContext } from "../ScheduleTabs";

export default function DeleteEventsButton() {
  const context = useContext(ScheduleContext);
  const params = useParams<"schedule_id">();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  async function deleteEvents(force?: boolean) {
    globalMessageHandler.confirm(
      {
        message: force
          ? "Biztosan tölröd a legenerált az eseményeket minden foglalásukkal együtt?"
          : "Biztosan tölröd a legenerált az eseményeket?",
      },
      async () => {
        setLoading(true);
        const { success, data, error } = await api.post(
          `/schedules/${params?.schedule_id}/delete-events`,
          { force }
        );

        setLoading(false);
        if (success) {
          if (error) {
            setError(false);
          }
          context?.load();
          globalMessageHandler.snack({
            severity: "success",
            message: `Sikeresen törölve. ${data.count} esemény.`,
          });
        } else {
          setError(error);
        }
      }
    );
  }
  return (
    <>
      <Tooltip title={"Események törlése"}>
        <Box>
          <IconButton
            color="error"
            onClick={() => deleteEvents()}
            disabled={
              !!(context?.isCreate || context?.loading || context?.isDirty) ||
              !context?.item?.has_event
            }
          >
            {loading ? <CircularProgress size={16} /> : <LayersClearIcon />}
          </IconButton>
        </Box>
      </Tooltip>
      <Dialog
        open={!!error}
        onClose={() => setError(undefined)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle onClose={() => setError(undefined)}>
          Sikertelen Törlés
        </DialogTitle>
        <DialogContent>
          <Alert severity="warning">{error?.message}</Alert>
          {error?.bookings?.length > 0 && (
            <Box>
              <Typography variant="h6" sx={{ pt: 2 }}>
                Foglalások
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{I18n.t("Booking.attributes.status")}</TableCell>
                    <TableCell>
                      {I18n.t("ProgramVariant.attributes.name")}
                    </TableCell>
                    <TableCell>
                      {I18n.t("Booking.attributes.booking_number")}
                    </TableCell>
                    <TableCell>{I18n.t("Booking.attributes.name")}</TableCell>
                    <TableCell>
                      {I18n.t("Event.attributes.start_time")}
                    </TableCell>
                    <TableCell>
                      {I18n.t("Booking.attributes.created_at")}
                    </TableCell>
                    <TableCell>{I18n.t("Booking.attributes.status")}</TableCell>
                    <TableCell>
                      {I18n.t("Event.attributes.deleted_at")}
                    </TableCell>
                    <TableCell>
                      {I18n.t("Booking.attributes.deleted_at")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {error?.bookings.map((booking: BookingItem) => {
                    return (
                      <TableRow key={booking.id}>
                        <TableCell width={16} padding="none">
                          <Box
                            sx={{
                              width: 12,
                              height: 12,
                              borderRadius: 6,
                              backgroundColor:
                                booking?.program_variant?.program?.color,
                            }}
                          ></Box>
                        </TableCell>
                        <TableCell>{booking?.program_variant?.name}</TableCell>
                        <TableCell>{booking.booking_number}</TableCell>
                        <TableCell>{booking.name}</TableCell>
                        <TableCell>
                          {moment(booking.event?.start_time).format("L LT")}
                        </TableCell>
                        <TableCell>
                          {moment(booking.created_at).format("L LT")}
                        </TableCell>
                        <TableCell>
                          {moment(booking.created_at).format("L LT")}
                        </TableCell>
                        <TableCell
                          sx={{
                            color:
                              booking.status === BOOKING_STATUSES.ACTIVE
                                ? "success.main"
                                : "error.main",
                          }}
                        >
                          {I18n.t(`Booking.options.status.${booking.status}`)}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: booking.deleted_at
                              ? "error.main"
                              : "success.main",
                          }}
                        >
                          {booking.deleted_at
                            ? moment(booking.deleted_at).format("L LT")
                            : ""}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: booking.event?.deleted_at
                              ? "error.main"
                              : "success.main",
                          }}
                        >
                          {booking.event?.deleted_at
                            ? moment(booking.event?.deleted_at).format("L LT")
                            : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            title="Törlés a foglalásokkal együtt"
            variant="outlined"
            color="error"
            onClick={() => deleteEvents(true)}
            disabled={loading}
          />
          <Spacer right />
          <Button
            title="Ok"
            disabled={loading}
            onClick={() => setError(undefined)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
