import CommentIcon from "@mui/icons-material/Comment";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import { Box, ListItem, ListItemText, Typography } from "@mui/material";
import { Toolbar } from "common";
import useDictionaries from "features/dictionaries/useDictionaries";
import { ProgramItem } from "features/programs/programApi";
import { Booking } from "./kitchenApi";
import OrderItem from "./OrderItem";

const summaryColors = {
  option: "lightblue",
  variant: "lightgreen",
};

function BookingListItem({ booking }: { booking: Booking }) {
  const { resolveOption } = useDictionaries();
  const program = resolveOption<ProgramItem>("programs", booking?.program_id);
  const qty: number =
    (booking.number_of_adults || 0) + (booking.number_of_kids || 0);
  const qty_babies: number = booking.number_of_babies || 0;
  const qty_guides: number = booking.number_of_guides || 0;
  return (
    <ListItem divider>
      <ListItemText
        disableTypography
        primary={
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="wrap"
            gap={1}
          >
            <Box display="flex" flexDirection="column">
              <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                {booking.name}
              </Typography>
              {booking.description && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                    color: theme => theme.palette.info.main,
                  }}
                >
                  <CommentIcon fontSize="small" />
                  <Typography variant="body2">{booking.description}</Typography>
                </Box>
              )}
              <Toolbar sx={{ gap: 1 }}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "5px",
                    backgroundColor: program?.color,
                  }}
                />
                <Typography>
                  <b>{qty}</b> fő{qty_babies > 0 ? ` + ${qty_babies} baba` : ""}
                  {qty_guides > 0 ? ` + ${qty_guides} baba` : ""}
                </Typography>

                {booking.table_numbers && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <EventSeatIcon fontSize="small" />
                    <Typography variant="body1" fontWeight={500}>
                      {booking.table_numbers?.join(", ")}
                    </Typography>
                  </Box>
                )}
              </Toolbar>
            </Box>
            <Box display="flex" flexDirection="row" gap={1} flexWrap="wrap">
              {booking.variant_name && (
                <OrderItem
                  name={booking.variant_name}
                  qty={qty}
                  type="variant"
                />
              )}
              {booking.booking_options.map((option, idx) => (
                <OrderItem
                  key={idx}
                  name={option.name}
                  qty={option.qty}
                  type="option"
                />
              ))}
            </Box>
          </Box>
        }
      />
    </ListItem>
  );
}

export default BookingListItem;
