import { useParams } from "common/components/Form/ParamsContext";
import { getPartnerCommissionService } from "./partnerCommissionApi";

export default function usePartnerCommissionList() {
  const params = useParams();
  const scope = { partners: params.partner_id };
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "partner-commissions/"
      : ""
  }`;
  const service = getPartnerCommissionService({ partners: params.partner_id });

  return { ...service, params, scope, editUrl };
}
