import api from "app/api";
import { Options } from "common";
import { BookingOptionItem } from "features/booking-options/bookingOptionApi";
import { EventItem, EventOption } from "features/events/eventApi";
import { OptionGroupItem } from "features/option-groups/optionGroupApi";
import { OptionItem } from "features/options/optionApi";
import { PartnerItem } from "features/partners/partnerApi";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import { BOOKING_PAY_LOCATIONS } from "./bookingConstants";
export interface BookingItem {
  id: number;
  booking_number: string;
  external_booking_number?: string;
  event_id: number;
  event?: EventItem;
  program_variant_id: number;
  program_variant?: ProgramVariantItem;
  custom_partner: boolean;
  partner_name: string;
  partner_id: number;
  partner?: PartnerItem;
  status: string;
  name: string;
  email: string;
  phone: string;
  number_of_adults: number;
  number_of_kids: number;
  number_of_babies: number;
  number_of_guests: number;
  number_of_guides: number;
  paid: boolean;
  check_in_at: string;
  description: string;
  sku: string;
  adult_price: number;
  kid_price: number;
  planned: boolean;
  planned_event_id: number;
  planned_event: EventItem;
  planned_datetime: string;
  table_numbers: string;
  upgrade_price?: number | null;
  upgrade?: boolean;
  voucher?: string;
  pay_location?: BOOKING_PAY_LOCATIONS;
  booking_options?: BookingOptionItem[];
  created_at: string;
  deleted_at?: string;
}
//Add database attributes
export type ExtendedEventOption = OptionItem & EventOption;

export type BookingOptionGroupItem = {
  items: ExtendedEventOption[];
} & OptionGroupItem;

export type BookingOptions = Options<"role" | "status">;

export function getBookingService(scope?: any) {
  return api.getService<BookingItem, BookingItem, BookingOptions>("bookings", {
    scope,
    //params goes here
  });
}
export function bookingSearch(params?: any) {
  return api.get<BookingItem[]>("bookings/search", {
    params,
  });
}
export function setTableNumbers(id: any, tableNumbers: string[] | null) {
  return api.post<BookingItem>(`bookings/${id}/set-table-numbers`, {
    tableNumbers,
  });
}
export function checkIn(id: any) {
  return api.post<BookingItem>(`bookings/${id}/check-in`);
}
export function checkOut(id: any) {
  return api.post<BookingItem>(`bookings/${id}/check-out`);
}

export function cancelBookingsByIds(bookingIds?: any[]) {
  return api.post<{ count: number }>(`booking/cancel`, {
    bookingIds,
  });
}

export function getUpgradeVariants(id: any) {
  return api.get<ProgramVariantItem[]>(`bookings/${id}/upgrade-variants`);
}

export type UpgradeItem = Partial<BookingItem>;
export function upgrade(id: any, item: UpgradeItem) {
  return api.post<ProgramVariantItem[]>(`bookings/${id}/upgrade`, item);
}
