import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import {
  Box,
  BoxProps,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import { Button, DialogTitle, Spacer, Toolbar, useDialog } from "common";
import Dialog from "components/DraggableDialog/DraggableDialog";
import FilterProvider from "components/FilterProvider";
import BookingList from "features/bookings/BookingList";
import { EventItem } from "features/events/eventApi";
import moment from "moment";
import { useEventCalculator } from "../../useEventCalculator";
import { BoatGroup } from "../cells/useStartBoatCell";
import EventCard from "./EventCard";

export interface StartBoatGroupDayProps extends Omit<BoxProps, "onClick"> {
  item: BoatGroup;
  onClick?: (item: EventItem) => void;
  onAddClick?: (item: EventItem) => void;
  eventProps?: Omit<BoxProps, "onClick">;
  isSelectedFunc?: (item: EventItem) => boolean;
}
export default function StartBoatGroupDay({
  item,
  onClick,
  onAddClick,
  sx,
  eventProps,
  isSelectedFunc,
  ...props
}: StartBoatGroupDayProps) {
  const theme = useTheme();
  const { getBoatData } = useEventCalculator();
  const { boatLimit, boatSx, boatClassName } = getBoatData(item.events[0]);
  const [bookingList, setBookingList, bookingListProps] = useDialog();
  return (
    <Box
      className={boatClassName}
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: "1px",
        borderRadius: "5px",
        p: 1,
        ...(boatSx as any),
        ...sx,
      }}
    >
      <Toolbar sx={{ gap: 0.5 }}>
        <EventIcon
          fontSize="medium"
          //sx={{ fontSize: "0.9rem" }}
        />
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {moment(item.events[0].start_time).format("LT")}
        </Typography>
        <Typography variant="caption" sx={{ fontWeight: "bold", opacity: 0.8 }}>
          ({item.events[0].duration} perc)
        </Typography>
        <Spacer right />
        <Button
          variant="text"
          size="small"
          title="foglalások"
          color="secondary"
          onClick={() => setBookingList(true)}
        />
        <PersonIcon sx={{ fontSize: "0.9rem" }} />
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {boatLimit?.reserved_places}/{boatLimit?.number_of_places}
        </Typography>
      </Toolbar>
      <Toolbar sx={{ gap: 1, flex: 1 }}>
        {item.events.map(event => (
          <EventCard
            sx={{ flex: 1 }}
            key={event.id}
            boatLimit={boatLimit}
            item={event}
            isSelected={isSelectedFunc ? isSelectedFunc(event) : undefined}
            onClick={onClick ? () => onClick && onClick(event) : undefined}
            onAddClick={
              onAddClick ? () => onAddClick && onAddClick(event) : undefined
            }
            {...eventProps}
          />
        ))}
      </Toolbar>
      <Dialog {...bookingListProps} maxWidth="xl" fullWidth>
        <DialogTitle onClose={bookingListProps.onClose}>Foglalások</DialogTitle>
        <DialogContent>
          <FilterProvider
            id="calendarCell.bookings"
            storageType="none"
            defaultFilter={{ eventIds: item.events.map(e => e.id) }}
          >
            <BookingList
              id="booking_list_calendar"
              showHeader={false}
              showFooter={false}
              hideCreate
            />
          </FilterProvider>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
