import HistoryIcon from "@mui/icons-material/History";
import { DialogContent } from "@mui/material";
import {
  DataGridV2 as DataGrid,
  DataGridLinkColumn,
  GridColumns,
  PageHeader,
} from "common";
import Dialog from "components/DraggableDialog/DraggableDialog";

import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import HistoryForm from "./HistoryForm";
import { HistoryListProps } from "./HistoryList";
import { HistoryItem } from "./historyApi";
import useHistoryList from "./useHistoryList";
export interface HistoryListDialogProps extends HistoryListProps {
  subtitle?: string;
  open?: boolean;
  onClose?:
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined;
}

export default function HistoryListDialog({
  open,
  onClose,
  subtitle,
  ...props
}: HistoryListDialogProps) {
  const { params, ...service } = useHistoryList();
  const { hasPermissions } = useAccess();
  let columns: GridColumns<HistoryItem> = [
    {
      field: "class_name",
      headerName: I18n.t("History.attributes.class_name"),
      flex: 1,
    },
    {
      field: "item_id",
      headerName: I18n.t("History.attributes.item_id"),
      type: "number",
    },
    {
      field: "data",
      headerName: I18n.t("History.attributes.data"),
      hide: true,
    },
    {
      hide: params.user_id ? true : false,
      field: "user.name",
      headerName: I18n.t("History.attributes.user_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/users/${params.row?.user_id}`}
          params={params}
        />
      ),
    },
    {
      field: "created_at",
      headerName: I18n.t("History.attributes.created_at"),
      type: "datetime",
    },
  ];

  return (
    <Dialog open={!!open} onClose={onClose} fullWidth maxWidth="md">
      <PageHeader
        avatar={<HistoryIcon />}
        title={I18n.t("History.title")}
        subtitle={subtitle}
        onClose={onClose}
      />
      <DialogContent>
        <DataGrid
          {...service}
          columns={columns}
          id="histories"
          renderModalForm={props => <HistoryForm {...props} />}
          {...props}
          //getHeaderName={field => I18n.t(`History.attributes.${field.replaceAll(".", "_")}`)}
          canCreate={hasPermissions("history.create")}
          canDelete={hasPermissions(["history.editany", "history.editown"])}
          canEdit={hasPermissions(["history.editany", "history.editown"])}
          /*canBatchActions={hasPermissions([
            "history.editany",
            "history.editown",
          ])}*/
          canHandleTrash={hasPermissions([
            "history.editany",
            "history.editown",
          ])}
        />
      </DialogContent>
    </Dialog>
  );
}
