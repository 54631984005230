import BusinessIcon from "@mui/icons-material/Business";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  GridColumns,
  PageHeader,
} from "common";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { PartnerCommissionItem } from "./partnerCommissionApi";
import PartnerCommissionForm from "./PartnerCommissionForm";
import usePartnerCommissionList from "./usePartnerCommissionList";

export interface PartnerCommissionListProps
  extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function PartnerCommissionList({
  showHeader = true,
  ...props
}: PartnerCommissionListProps) {
  const { params, scope, editUrl, ...service } = usePartnerCommissionList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<PartnerCommissionItem> = [
    {
      hide: !!params.partner_id,
      hideable: !params.partner_id,
      field: "partner_id",
      headerName: I18n.t("PartnerCommission.attributes.partner_id"),
      flex: 1,
    },
    {
      field: "headcount",
      headerName: I18n.t("PartnerCommission.attributes.headcount"),
      flex: 1,
    },
    {
      field: "commission_rate",
      headerName: I18n.t("PartnerCommission.attributes.commission_rate"),
      flex: 1,
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          avatar={<BusinessIcon />}
          title={I18n.t("PartnerCommission.title")}
        />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="partner-commissions"
        renderModalForm={props => <PartnerCommissionForm {...props} />}
        modalProps={{ maxWidth: "sm" }}
        {...props}
        canCreate={hasPermissions("partner.create")}
        canDelete={hasPermissions(["partner.editany", "partner.editown"])}
        canEdit={hasPermissions(["partner.editany", "partner.editown"])}
        canDeleteRow={params => canEditItem("partner", params.row)}
        canEditRow={params => canEditItem("partner", params.row)}
        canBatchActions={hasPermissions(["partner.editany", "partner.editown"])}
        canHandleTrash={hasPermissions(["partner.editany", "partner.editown"])}
      />
    </>
  );
}
