import {
  globalMessageHandler,
  GridModalFormProps,
  useForm,
  UseFormReturnType,
  Validator,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import I18n from "i18n";
import { Params } from "react-router-dom";
import {
  getPartnerCommissionService,
  PartnerCommissionItem,
  PartnerCommissionOptions,
} from "./partnerCommissionApi";

export interface UsePartnerCommissionFormProps extends GridModalFormProps {}

type RouteParams = {
  partner_commission_id?: string;
};

export interface UsePartnerCommissionFormReturnType
  extends UseFormReturnType<PartnerCommissionItem, PartnerCommissionOptions> {
  params?: Readonly<Params<"partner_commission_id" | "partner_id">>;
}

export default function usePartnerCommissionForm({
  id,
  afterSave,
  defaultValues,
}: UsePartnerCommissionFormProps): UsePartnerCommissionFormReturnType {
  const params = useParams<"partner_commission_id" | "partner_id">();
  const scope = { partners: params.partner_id };
  const service = getPartnerCommissionService(scope);

  const form = useForm<PartnerCommissionItem, PartnerCommissionOptions>({
    id: params.partner_commission_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "PartnerCommission",
    rules: {
      partner_id: params.partner_id
        ? Validator.rule.optional()
        : Validator.rule.number().required(),
      headcount: Validator.rule.number().required(),
      commission_rate: Validator.rule.number().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: undefined,
  });

  return { ...form, params };
}
