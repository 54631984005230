import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Button,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from "@mui/material";
import Dialog from "components/DraggableDialog/DraggableDialog";
import I18n from "i18n";
import { ReactNode, useState } from "react";

export interface HelpButtonProps extends Omit<IconButtonProps, "children"> {
  icon?: ReactNode;
  title: string;
  children: ReactNode;
  dialogProps?: DialogProps;
  dialogTitleProps?: DialogTitleProps;
  dialogContentProps?: DialogContentProps;
  dialogActionsProps?: DialogActionsProps;
  tooltipProps?: TooltipProps;
}

const HelpButton: React.FC<HelpButtonProps> = ({
  icon = <HelpOutlineIcon />,
  title,
  children,
  dialogProps,
  dialogTitleProps,
  dialogContentProps,
  dialogActionsProps,
  tooltipProps,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={title} {...tooltipProps}>
        <IconButton onClick={handleClickOpen} color="info" {...props}>
          {icon}
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        {...dialogProps}
      >
        <DialogTitle {...dialogTitleProps}>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers {...dialogContentProps}>
          {children}
        </DialogContent>
        <DialogActions {...dialogActionsProps}>
          <Button onClick={handleClose} color="primary">
            {I18n.t("App.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HelpButton;
