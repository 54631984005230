import { Box, BoxProps, Typography } from "@mui/material";

const summaryColors = {
  option: "lightblue",
  variant: "lightgreen",
};

function OrderItem({
  name,
  qty,
  type,
  ...props
}: {
  name: string;
  qty: number;
  type: "variant" | "option";
} & BoxProps) {
  return (
    <Box display="flex" alignItems="center" {...props}>
      <Typography variant="body1" fontWeight="bold" color="text.secondary">
        {name}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 700 }} ml={0.5}>
        {qty}
      </Typography>
    </Box>
  );
}
export default OrderItem;
