import {
  Box,
  BoxProps,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { InfoOutlined } from "@mui/icons-material";
import AddCircle from "@mui/icons-material/AddCircle";
import { Spacer, Toolbar } from "common";
import { EventItem, EventLimit } from "features/events/eventApi";
import {
  getLimitClassName,
  useEventCalculator,
} from "../../useEventCalculator";
import {
  openCalendarBookingModal,
  openCalendarEventModal,
} from "../EventCalendarModals";

export interface StartBoatCellViewMonthProps extends Omit<BoxProps, "onClick"> {
  item: EventItem;
  boatLimit?: EventLimit;
  isSelected?: boolean;
  onClick?: (item: EventItem) => void;
  onAddClick?: (item: EventItem) => void;
}
export default function EventCard({
  item,
  boatLimit,
  onClick,
  onAddClick,
  isSelected,
  sx,
  ...props
}: StartBoatCellViewMonthProps) {
  const theme = useTheme();

  const { getEventData } = useEventCalculator();
  const {
    eventLimit,
    programColor,
    programTextColor,
    programBorderColor,
    limits,
    eventSx,
    eventClassName,
  } = getEventData(item, boatLimit);
  const limitsLabel = limits
    .map((limit, index) => {
      return limit.name?.substring(0, 1);
    })
    .join("");
  const remainingSpaces =
    (eventLimit?.remaining_places || 0) < 10
      ? eventLimit?.remaining_places
      : "+";
  const label = `${limitsLabel}${
    limits.length > 0 ? "|" : ""
  }${remainingSpaces}`;
  return (
    <Box
      className={`${eventClassName}${isSelected ? " selected" : ""}`}
      onClick={() => {
        if (onClick) {
          onClick(item);
        } else {
          openCalendarEventModal(item);
        }
      }}
      key={item.id}
      sx={{
        p: 0.5,
        alignSelf: "stretch",
        borderRadius: "4px",
        cursor: "pointer",
        gap: 0.5,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        ...(eventSx as any),
        ...sx,
      }}
    >
      <Toolbar
        sx={{
          gap: 0.5,
          overflow: "hidden",
          textOverflow: "ellipsis",
          position: "relative",
          //width: "60px",
        }}
      >
        <Typography
          variant="button"
          sx={{
            maxWidth: `calc(100% - 27px)`,
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {item.program.name}
        </Typography>
        <Spacer right />
        <IconButton
          sx={{
            alignSelf: "flex-start",
            mr: -0.5,
          }}
          size="small"
          color={"inherit"}
          onClick={evt => {
            evt.stopPropagation();
            openCalendarBookingModal(item);
          }}
        >
          <AddCircle fontSize="small" />
        </IconButton>
      </Toolbar>
      <Tooltip title="elérhető / foglalt / limit">
        <Box
          sx={{
            borderRadius: "3px",
            paddingLeft: "2px",
            paddingRight: "2px",
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Typography
            //variant="body1"
            sx={{ fontWeight: 500 }}
          >
            {eventLimit?.remaining_places}/{eventLimit?.reserved_places}/
            {eventLimit?.number_of_places}
          </Typography>
          <InfoOutlined sx={{ color: "inherit" }} fontSize="small" />
        </Box>
      </Tooltip>
      {limits.map((limit, index) => {
        const limitClassName = getLimitClassName(limit);
        return (
          <Toolbar key={index} sx={{ gap: 0.5 }}>
            <Box
              className={limitClassName}
              sx={{
                minWidth: 18,
                height: 18,
                borderRadius: "9px",
                backgroundColor: "white",
                color: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontWeight: 500 }} variant="body2">
                {limit.name?.substring(0, 1).toUpperCase()}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {limit?.remaining_places}/{limit?.reserved_places}/
              {limit?.number_of_places}
            </Typography>
            <IconButton size="small">
              <InfoOutlined />
            </IconButton>
          </Toolbar>
        );
      })}
    </Box>
  );
}
