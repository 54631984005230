import HandshakeIcon from "@mui/icons-material/Handshake";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridLinkColumn,
  DataGridModalEditColumn,
  GridColumns,
  PageHeader,
} from "common";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { PartnerItem } from "./partnerApi";
import PartnerForm from "./PartnerForm";
import usePartnerList from "./usePartnerList";
export interface PartnerListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function PartnerList({
  showHeader = true,
  ...props
}: PartnerListProps) {
  const { params, scope, editUrl, ...service } = usePartnerList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<PartnerItem> = [
    {
      field: "name",
      headerName: I18n.t("Partner.attributes.name"),
      renderCell: params => (
        <DataGridModalEditColumn
          href={`${editUrl}${params.row?.id}`}
          params={params}
        />
      ),
      flex: 1,
    },
    { field: "email", headerName: I18n.t("Partner.attributes.email"), flex: 1 },
    { field: "phone", headerName: I18n.t("Partner.attributes.phone"), flex: 1 },
    {
      field: "customer_email",
      headerName: I18n.t("Partner.attributes.customer_email"),
      flex: 1,
    },
    {
      field: "customer_phone",
      headerName: I18n.t("Partner.attributes.customer_phone"),
      flex: 1,
    },
    {
      hide: !!params.partner_group_id,
      hideable: !params.partner_group_id,
      field: "partner_group.name",
      headerName: I18n.t("Partner.attributes.partner_group_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/partner-groups/${params.row?.partner_group_id}`}
          params={params}
        />
      ),
    },
    {
      field: "online",
      headerName: I18n.t("Partner.attributes.online"),
      type: "boolean",
    },
    {
      field: "active",
      headerName: I18n.t("Partner.attributes.active"),
      type: "boolean",
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          avatar={<HandshakeIcon />}
          title={I18n.t("Partner.title")}
        />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="partners"
        renderModalForm={props => <PartnerForm {...props} />}
        modalProps={{ maxWidth: "lg" }}
        defaultState={{
          hidden_columns: ["customer_email", "customer_phone"],
        }}
        {...props}
        //getHeaderName={field => I18n.t(`Partner.attributes.${field.replaceAll(".", "_")}`)}
        canCreate={hasPermissions("partner.create")}
        canDelete={hasPermissions(["partner.editany", "partner.editown"])}
        canEdit={hasPermissions(["partner.editany", "partner.editown"])}
        canDeleteRow={params => canEditItem("partner", params.row)}
        canEditRow={params => canEditItem("partner", params.row)}
        canBatchActions={hasPermissions(["partner.editany", "partner.editown"])}
        canHandleTrash={hasPermissions(["partner.editany", "partner.editown"])}
      />
    </>
  );
}
