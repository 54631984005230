import { ROLES } from "app/constants";
import { useAppSelector } from "app/hooks";
import {
  selectProfile,
  selectProfilePermissions,
  selectProfileRoles,
} from "./profileSlice";

export default function useAccess() {
  const profile = useAppSelector(selectProfile);
  const roles = useAppSelector(selectProfileRoles);
  const permissions = useAppSelector(selectProfilePermissions);

  function canEditItem(table: string, item: any) {
    const onlyOwn =
      hasPermissions(`${table}.editown`) && !hasPermissions(`${table}.editany`);
    if (onlyOwn) {
      return !(item.created_by === profile.id);
    }
    return true;
  }
  function hasRoles(role: string | string[], roleList?: string[]) {
    const _roles = roleList || roles;
    if (!_roles) {
      return false;
    }
    if (typeof role === "string") {
      return _roles.includes(role);
    } else {
      return !!role.find(function (value) {
        return _roles.includes(value);
      });
    }
  }
  function notHasRoles(role: string | string[]) {
    let allRoles = [
      ROLES.ADMIN,
      ROLES.CATERING,
      ROLES.COORDINATOR,
      ROLES.CUSTOMER_CARE,
      ROLES.HEAD_OFFICE,
      ROLES.KITCHEN,
      ROLES.OTA,
      ROLES.REPORTING,
      ROLES.STREET_SALES,
      ROLES.SUPERADMIN,
    ];
    let __role = typeof role === "string" ? [role] : role;
    const _roles: any[] = allRoles.filter(r => !__role.includes(r));
    if (!_roles) {
      return false;
    }
    return !!roles.find(function (value) {
      return _roles.includes(value);
    });
  }
  function hasPermissions(permission: string | string[]) {
    if (!permissions) {
      return false;
    }
    if (typeof permission === "string") {
      return permissions.includes(permission);
    } else {
      return permission.some(function (value) {
        return permissions.indexOf(value) >= 0;
      });
    }
  }
  return {
    hasRoles,
    hasPermissions,
    canEditItem,
    notHasRoles,
  };
}
