import { DialogProps, Dialog as OriginalDialog, Paper } from "@mui/material";
import React from "react";
import Draggable from "react-draggable";

interface DraggableDialogProps extends DialogProps {
  PaperComponent?: React.ComponentType<any>;
}

const DraggableDialog = React.forwardRef<HTMLDivElement, DraggableDialogProps>(
  function DraggableDialog(props, ref) {
    const { PaperComponent, ...rest } = props;

    return (
      <OriginalDialog
        ref={ref}
        PaperComponent={
          PaperComponent ||
          ((paperProps: any) => (
            <Draggable
              //handle=".MuiDialogTitle-root"
              handle=".MuiPageHeader, .MuiDialogTitle-root"
              cancel={'[class*="MuiDialogContent-root"]'}
            >
              <Paper {...paperProps} />
            </Draggable>
          ))
        }
        {...rest}
      />
    );
  }
);

export default DraggableDialog;
