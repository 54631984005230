export enum ROLES {
  SUPERADMIN = "SuperAdmin",
  ADMIN = "Admin",
  REPORTING = "Reporting",
  COORDINATOR = "Coordinator",
  CUSTOMER_CARE = "CustomerCare",
  OTA = "OTA",
  STREET_SALES = "StreetSales",
  CATERING = "Catering",
  KITCHEN = "Kitchen",
  HEAD_OFFICE = "HeadOffice",
}

export enum NOTIFICATION_TYPES {
  SHIFT_CONFIRM = "SHIFT_CONFIRM",
  SHIFT_DECLINE = "SHIFT_DECLINE",
  SHIFT_PUBLISH = "SHIFT_PUBLISH",
  SHIFT_UPDATE = "SHIFT_UPDATE",
  SHIFT_REQUEST = "SHIFT_REQUEST",
  SHIFT_CANCEL = "SHIFT_CANCEL",
  MISC = "MISC",
}

export const COMPATIBILE_PROGRAMS: any = {
  4: [4, 8],
  8: [4, 8],
};

export enum Permissions {
  PermissionView = "permission.view",
  PermissionEdit = "permission.edit",
  RoleView = "role.view",
  RoleEdit = "role.edit",
  FileCreate = "file.create",
  FileView = "file.view",
  HistoryViewAny = "history.viewany",
  HistoryViewOwn = "history.viewown",
  UserViewAny = "user.viewany",
  UserViewOwn = "user.viewown",
  UserEditAny = "user.editany",
  UserEditOwn = "user.editown",
  UserCreate = "user.create",
  UserMenu = "user.menu",
  PartnerGroupViewAny = "partner_group.viewany",
  PartnerGroupViewOwn = "partner_group.viewown",
  PartnerGroupEditAny = "partner_group.editany",
  PartnerGroupEditOwn = "partner_group.editown",
  PartnerGroupCreate = "partner_group.create",
  PartnerGroupMenu = "partner_group.menu",
  SeatingLayoutViewAny = "seating_layout.viewany",
  SeatingLayoutViewOwn = "seating_layout.viewown",
  SeatingLayoutEditAny = "seating_layout.editany",
  SeatingLayoutEditOwn = "seating_layout.editown",
  SeatingLayoutCreate = "seating_layout.create",
  SeatingLayoutMenu = "seating_layout.menu",
  PartnerViewAny = "partner.viewany",
  PartnerViewOwn = "partner.viewown",
  PartnerEditAny = "partner.editany",
  PartnerEditOwn = "partner.editown",
  PartnerCreate = "partner.create",
  PartnerMenu = "partner.menu",
  ProgramViewAny = "program.viewany",
  ProgramViewOwn = "program.viewown",
  ProgramEditAny = "program.editany",
  ProgramEditOwn = "program.editown",
  ProgramCreate = "program.create",
  ProgramMenu = "program.menu",
  ProgramVariantViewAny = "program_variant.viewany",
  ProgramVariantViewOwn = "program_variant.viewown",
  ProgramVariantEditAny = "program_variant.editany",
  ProgramVariantEditOwn = "program_variant.editown",
  ProgramVariantCreate = "program_variant.create",
  ProgramVariantMenu = "program_variant.menu",
  OptionGroupViewAny = "option_group.viewany",
  OptionGroupViewOwn = "option_group.viewown",
  OptionGroupEditAny = "option_group.editany",
  OptionGroupEditOwn = "option_group.editown",
  OptionGroupCreate = "option_group.create",
  OptionGroupMenu = "option_group.menu",
  ProgramOptionViewAny = "program_option.viewany",
  ProgramOptionViewOwn = "program_option.viewown",
  ProgramOptionEditAny = "program_option.editany",
  ProgramOptionEditOwn = "program_option.editown",
  ProgramOptionCreate = "program_option.create",
  ProgramOptionMenu = "program_option.menu",
  BoatViewAny = "boat.viewany",
  BoatViewOwn = "boat.viewown",
  BoatEditAny = "boat.editany",
  BoatEditOwn = "boat.editown",
  BoatCreate = "boat.create",
  BoatMenu = "boat.menu",
  ScheduleViewAny = "schedule.viewany",
  ScheduleViewOwn = "schedule.viewown",
  ScheduleEditAny = "schedule.editany",
  ScheduleEditOwn = "schedule.editown",
  ScheduleCreate = "schedule.create",
  ScheduleMenu = "schedule.menu",
  EventViewAny = "event.viewany",
  EventViewOwn = "event.viewown",
  EventEditAny = "event.editany",
  EventEditOwn = "event.editown",
  EventCreate = "event.create",
  EventMenu = "event.menu",
  EventClose = "event.close",
  BookingViewAny = "booking.viewany",
  BookingViewOwn = "booking.viewown",
  BookingEditAny = "booking.editany",
  BookingEditOwn = "booking.editown",
  BookingCreate = "booking.create",
  BookingMenu = "booking.menu",
  BookingEditPrice = "booking.editprice",
  BookingDinnerPlaces = "booking.dinnerplaces",
  BookingOptionViewAny = "booking_option.viewany",
  BookingOptionViewOwn = "booking_option.viewown",
  BookingOptionEditAny = "booking_option.editany",
  BookingOptionEditOwn = "booking_option.editown",
  BookingOptionCreate = "booking_option.create",
  BookingOptionMenu = "booking_option.menu",
  ExchangeRateViewAny = "exchange_rate.viewany",
  ExchangeRateViewOwn = "exchange_rate.viewown",
  ExchangeRateEditAny = "exchange_rate.editany",
  ExchangeRateEditOwn = "exchange_rate.editown",
  ExchangeRateCreate = "exchange_rate.create",
  ExchangeRateMenu = "exchange_rate.menu",
  BookingRegister = "booking.register",
  BookingSeating = "booking.seating",
  BookingMove = "booking.move",
  BookingUpgrade = "booking.upgrade",
}
