import { AssignmentReturned, AssignmentTurnedIn } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DiamondIcon from "@mui/icons-material/Diamond";
import DoubleArrow from "@mui/icons-material/DoubleArrow";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import KeyIcon from "@mui/icons-material/Key";
import {
  Box,
  Chip,
  ChipProps,
  IconButton,
  alpha,
  useTheme,
} from "@mui/material";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridLinkColumn,
  DataGridModalEditColumn,
  DateRangePicker,
  GridColumns,
  PageHeader,
  Spacer,
  TextField,
  Toolbar,
  enumToOptions,
  globalMessageHandler,
} from "common";
import {
  GridState,
  UseDataGridReturnType,
} from "common/components/DataGridV2/types";
import CheckboxGroup from "common/components/Inputs/CheckboxGroup/CheckboxGroup";
import BoatCheckboxSelector from "components/BoatCheckboxSelector/BoatCheckboxSelector";
import BookingButton from "components/BookingButton/BookingButton";
import ProgramCheckboxSelector from "components/ProgramCheckboxSelector/ProgramCheckboxSelector";
import { EVENT_STATUSES } from "features/events/eventConstants";
import useAccess from "features/profile/useAccess";
import UpgradeButton from "features/upgrades/UpgradeButton/UpgradeButton";
import I18n from "i18n";
import { size } from "lodash";
import moment from "moment";
import { ReactNode, useRef, useState } from "react";
import BookingForm from "./BookingForm";
import MoveBookingListModal from "./MoveBookings/MoveBookingListModal";
import { BookingItem } from "./bookingApi";
import { BOOKING_STATUSES } from "./bookingConstants";
import useBookingList from "./useBookingList";
export interface BookingListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
  defaultState?: Partial<GridState>;
  isInCalendar?: boolean;
  hideCreate?: boolean;
  id: string;
}

export default function BookingList({
  showHeader = true,
  isInCalendar,
  defaultState,
  id,
  hideCreate,
  ...props
}: BookingListProps) {
  const {
    params,
    scope,
    editUrl,
    listId,
    checkInAsync,
    checkOutAsync,
    filter,
    gridFilter,
    onChange,
    showMore,
    setShowMore,
    cancelBookingsByIdsAsync,
    ...service
  } = useBookingList(id);
  const gridRef = useRef<UseDataGridReturnType<BookingItem>>();
  const [bookingsToMove, setBookingsToMove] = useState<{
    bookingIds: number[];
    programIds: number[];
  }>();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<BookingItem> = [
    {
      field: "booking_number",
      headerName: I18n.t("Booking.attributes.booking_number"),
      renderCell: params => (
        <DataGridModalEditColumn
          href={`${editUrl}${params.row?.id}`}
          params={params}
        />
      ),
    },
    {
      field: "external_booking_number",
      headerName: I18n.t("Booking.attributes.external_booking_number"),
    },
    {
      valueFormatter: params =>
        `${moment(params?.row?.event?.start_time).format("L LT")} ${
          params?.row?.event?.program?.name
        }`,
      field: "event.start_time",
      headerName: I18n.t("Event.attributes.start_time"),
      renderCell: params =>
        params?.row?.planned
          ? moment(params?.row?.planned_datetime).format("L LT")
          : moment(params?.row?.event?.start_time).format("L LT"),
    },
    {
      field: "event.boat.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("Event.attributes.boat_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/boats/${params.row?.event?.boat_id}`}
          params={params}
        />
      ),
    },
    {
      field: "event.program.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("Event.attributes.program_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/programs/${params.row?.event?.program_id}`}
          params={params}
        />
      ),
    },
    {
      field: "event.schedule.{program.name} {date_from}-{date_to}",
      hide: true,
      hideable: true,
      headerName: I18n.t("Event.attributes.schedule_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/schedules/${params.row?.event?.schedule_id}`}
          params={params}
        />
      ),
    },
    {
      hide: !!params.program_variant_id,
      hideable: !params.program_variant_id,
      field: "program_variant.name",
      headerName: I18n.t("Booking.attributes.program_variant_id"),
      renderCell: params => (
        <Toolbar sx={{ gap: 1 }}>
          <Box
            sx={{
              width: 10,
              minWidth: 10,
              height: 10,
              backgroundColor: params.row.program_variant?.program?.color,
              borderRadius: "5px",
            }}
          />
          {params.formattedValue}
        </Toolbar>
      ),
    },
    {
      field: "program_variant.program.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("ProgramVariant.attributes.program_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/programs/${params.row?.program_variant?.program_id}`}
          params={params}
        />
      ),
    },
    {
      field: "custom_partner",
      headerName: I18n.t("Booking.attributes.custom_partner"),
      type: "boolean",
      hideable: true,
    },
    /*{
      field: "partner_name",
      headerName: I18n.t("Booking.attributes.partner_name"),
      hideable: true,
      flex: 1,
    },*/
    {
      hide: !!params.partner_id,
      hideable: !params.partner_id,
      field: "partner.name",
      headerName: I18n.t("Booking.attributes.partner_id"),
      renderCell: params => {
        if (params?.row?.custom_partner) {
          return params?.row?.partner_name;
        }
        return (
          <DataGridLinkColumn
            href={`/partners/${params.row?.partner_id}`}
            params={params}
          />
        );
      },
    },
    {
      field: "partner.partner_group.name",
      hide: true,
      hideable: true,
      headerName: I18n.t("Partner.attributes.partner_group_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/partner-groups/${params.row?.partner?.partner_group_id}`}
          params={params}
        />
      ),
    },
    {
      field: "status",
      headerName: I18n.t("Booking.attributes.status"),
      type: "singleSelect",
      valueOptions: enumToOptions(BOOKING_STATUSES, "Booking.options.status"),
    },
    { field: "name", headerName: I18n.t("Booking.attributes.name"), flex: 1 },
    { field: "email", headerName: I18n.t("Booking.attributes.email"), flex: 1 },
    {
      field: "phone",
      headerName: I18n.t("Booking.attributes.phone"),
      hideable: true,
      flex: 1,
    },
    {
      field: "number_of_adults",
      headerName: I18n.t("Booking.attributes.number_of_adults"),
      hideable: true,
      type: "number",
    },
    {
      field: "number_of_kids",
      headerName: I18n.t("Booking.attributes.number_of_kids"),
      hideable: true,
      type: "number",
    },
    {
      field: "number_of_babies",
      headerName: I18n.t("Booking.attributes.number_of_babies"),
      hideable: true,
      type: "number",
    },
    {
      field: "number_of_guides",
      headerName: I18n.t("Booking.attributes.number_of_guides"),
      hideable: true,
      type: "number",
    },
    {
      field: "number_of_guests",
      headerName: I18n.t("Booking.attributes.number_of_guests"),
      type: "number",
    },
    {
      field: "description",
      headerName: I18n.t("Booking.attributes.description"),
      hideable: true,
      renderCell: params => {
        return (
          <Box
            sx={{
              overflow: "visible",
              textOverflow: "unset",
              whiteSpace: "wrap",
            }}
          >
            {params.formattedValue}
          </Box>
        );
      },
    },
    {
      field: "paid",
      headerName: I18n.t("Booking.attributes.paid"),
      type: "boolean",
      hideable: true,
      renderCell: params => {
        let color: ChipProps["color"] = "warning";
        let icon: ReactNode = <DriveFileRenameOutlineIcon />;
        if (params.row?.paid) {
          color = "success";
          icon = <CheckIcon />;
        } else {
          color = "error";
          icon = <CloseIcon />;
        }

        return (
          <Chip
            //className={rootClassName}
            //classes={{ icon: classes.icon }}
            size="small"
            icon={icon}
            label={params.row?.paid ? "Fizetve" : "Fizetetlen"}
            color={color}
          />
        );
      },
    },
    {
      field: "created_at",
      headerName: I18n.t("App.created_at"),
      hideable: true,
      type: "datetime",
    },
    {
      field: "voucher",
      headerName: I18n.t("Booking.attributes.voucher"),
      hideable: true,
    },
    {
      field: "pay_location",
      headerName: I18n.t("Booking.attributes.pay_location"),
      hideable: true,
    },
    {
      field: "created_by_name",
      headerName: I18n.t("App.created_by_name"),
      hideable: true,
      sortable: false,
      //renderCell: params => params.row?.created_by_name,
    },
    {
      field: "updated_at",
      headerName: I18n.t("App.updated_at"),
      hideable: true,
      type: "datetime",
    },
    {
      field: "updated_by_name",
      headerName: I18n.t("App.updated_by_name"),
      hideable: true,
    },
    {
      field: "check_in_at",
      headerName: I18n.t("Booking.attributes.check_in_at"),
      hideable: true,
      type: "datetime",
    },
    {
      field: "description",
      headerName: I18n.t("Booking.attributes.description"),
      hideable: true,
      hide: true,
    },
    {
      field: "sku",
      headerName: I18n.t("Booking.attributes.sku"),
      hideable: true,
      flex: 1,
    },
    {
      field: "adult_price",
      headerName: I18n.t("Booking.attributes.adult_price"),
      type: "currency",
      currencyOptions: {
        precision: 2,
        unit: "€",
        format: "%u %n",
        separator: ",",
        delimiter: " ",
        stripInsignificantZeros: true,
      },
      hideable: true,
      align: "right",
    },
    {
      field: "kid_price",
      headerName: I18n.t("Booking.attributes.kid_price"),
      type: "currency",
      currencyOptions: {
        precision: 2,
        unit: "€",
        format: "%u %n",
        separator: ",",
        delimiter: " ",
        stripInsignificantZeros: true,
      },
      hideable: true,
      align: "right",
    },
  ];
  const theme = useTheme();

  async function openMoveBookings(
    bookingIds: any[],
    load: () => void,
    state: GridState<BookingItem>
  ) {
    //console.log({ openMoveBookings: bookingIds });
    const programIds = state.data
      .filter(b => bookingIds.includes(b.id))
      .map(b => b.program_variant?.program_id as number);
    setBookingsToMove({ bookingIds, programIds });
  }

  return (
    <>
      {showHeader && (
        <>
          <PageHeader avatar={<KeyIcon />} title={I18n.t("Booking.title")}>
            <DateRangePicker
              value={{ from: filter?.range?.start, to: filter?.range?.end }}
              onChange={({ from, to }) =>
                onChange({
                  range: {
                    start: from as any,
                    end: to as any,
                  },
                })
              }
            />
          </PageHeader>
          <BoatCheckboxSelector
            value={filter?.boatIds}
            color={theme.palette.primary.main}
            onChange={evt => {
              onChange({ boatIds: (evt.target.value as any) || [] });
            }}
          />
          <Spacer h={2} />
          <ProgramCheckboxSelector
            value={filter?.programIds}
            onChange={evt => {
              onChange({ programIds: (evt.target.value as any) || [] });
            }}
            showMore={showMore}
            setShowMore={setShowMore}
          />
          <Spacer h={2} />
        </>
      )}
      <DataGrid
        renderCreateItem={
          isInCalendar
            ? undefined
            : ({ handleCreate }) => {
                return (
                  <BookingButton
                    buttonType="icon"
                    onSelect={(programId, event, planned, datetime) => {
                      if (planned) {
                        handleCreate({} as any, {
                          //event_id: event.id,
                          planned_datetime: datetime,
                          planned_event: event,
                          planned_event_id: event?.id,
                          planned: true,
                          status: EVENT_STATUSES.ACTIVE,
                        });
                      } else {
                        handleCreate({} as any, {
                          event_id: event?.id,
                          event: event,
                          status: EVENT_STATUSES.ACTIVE,
                        });
                      }
                    }}
                  >
                    <Add />
                  </BookingButton>
                );
              }
        }
        renderToolbarLeftItems={({ handleCreate }) => (
          <>
            <CheckboxGroup
              options={enumToOptions(
                BOOKING_STATUSES,
                "Booking.options.status"
              )}
              onChange={(evt: any) => onChange({ status: evt.target.value })}
              value={filter?.status}
            />
            {showHeader && (
              <CheckboxGroup
                options={[
                  { value: "scheduled", label: "Menetrend" },
                  { value: "planned", label: "Előfoglalás" },
                ]}
                onChange={(evt: any) => onChange({ types: evt.target.value })}
                value={filter?.types}
                color="secondary"
              />
            )}
            <TextField
              value={filter?.startTime}
              fullWidth={false}
              placeholder="időpont: 17:00 vagy 17"
              onChange={(evt: any) => onChange({ startTime: evt.target.value })}
            />
          </>
        )}
        {...service}
        filter={gridFilter}
        columns={columns}
        id={listId}
        apiRef={gridRef}
        renderModalForm={props => <BookingForm {...props} />}
        modalProps={{ maxWidth: "lg" }}
        {...props}
        //getHeaderName={field => I18n.t(`Booking.attributes.${field.replaceAll(".", "_")}`)}
        rowSx={(item: BookingItem) => {
          if (item.status === BOOKING_STATUSES.CANCELED) {
            return {
              backgroundColor: theme => alpha(theme.palette.error.main, 0.15),
            };
          }
          if (item.planned) {
            return {
              backgroundColor: theme => alpha(theme.palette.warning.main, 0.15),
            };
          }
          if (!!item.check_in_at) {
            return {
              backgroundColor: theme => alpha(theme.palette.success.main, 0.15),
            };
          }
          return {};
        }}
        defaultState={{
          sorting: [
            { field: "status", direction: "desc" },
            { field: "created_at", direction: "desc" },
          ],
          hidden_columns: [
            "event_id",
            "boat_id",
            "program_id",
            "schedule_id",
            "custom_partner",
            "partner_name",
            "partner_group_id",
            "phone",
            "email",
            "number_of_adults",
            "number_of_kids",
            "number_of_babies",
            "number_of_guides",
            "paid",
            "check_in_at",
            "description",
            "sku",
            "adult_price",
            "kid_price",
            "created_at",
            "updated_at",
            "updated_by_name",
            "voucher",
            "pay_location",
          ],
          ...defaultState,
        }}
        canCreate={!hideCreate && hasPermissions("booking.create")}
        canDelete={hasPermissions(["booking.editany", "booking.editown"])}
        canEdit={hasPermissions(["booking.editany", "booking.editown"])}
        canDeleteRow={params => canEditItem("booking", params.row)}
        canEditRow={params => canEditItem("booking", params.row)}
        canBatchActions={hasPermissions(["booking.editany", "booking.editown"])}
        canHandleTrash={hasPermissions(["booking.editany", "booking.editown"])}
        rowActions={(params, openEdit, refresh) => {
          let actions: any[] = [];
          actions.push(
            <UpgradeButton
              booking={params.row}
              buttonType="icon"
              key="upgrade"
              size="small"
              afterUpgrade={item => {
                refresh();
              }}
            >
              <DiamondIcon />
            </UpgradeButton>
          );
          actions.push(
            <IconButton
              key="move"
              size="small"
              title={I18n.t("Booking.moveTitle")}
              onClick={() => {
                setBookingsToMove({
                  bookingIds: [params.row?.id],
                  programIds: [params.row?.program_variant?.program_id],
                });
              }}
            >
              <DoubleArrow />
            </IconButton>
          );
          actions.push(
            <IconButton
              key="reg"
              size="small"
              title={
                !!params.row?.check_in_at
                  ? "Vendég érkeztetés törlése"
                  : "Vendég érkeztetés"
              }
              onClick={() => {
                if (!!params.row?.check_in_at) {
                  globalMessageHandler.confirm(
                    {
                      message: "Biztosan törlöd a vendég érkeztetést?",
                    },
                    async () => {
                      await checkOutAsync(params.row);
                      refresh();
                    }
                  );
                } else {
                  globalMessageHandler.confirm(
                    {
                      message: `Biztosan regisztrálod az alábbi foglalást? ${params.row.name}`,
                    },
                    async () => {
                      await checkInAsync(params.row);
                      refresh();
                    }
                  );
                }
              }}
            >
              {!!params.row?.check_in_at ? (
                <AssignmentTurnedIn />
              ) : (
                <AssignmentReturned />
              )}
            </IconButton>
          );
          return actions;
        }}
        bulkActions={[
          {
            id: "moveBookings",
            label: "Foglalások áthelysezése",
            action: openMoveBookings,
            icon: <DoubleArrow />,
          },
        ]}
      />
      {size(bookingsToMove?.bookingIds) > 0 && (
        <MoveBookingListModal
          bookingIds={bookingsToMove?.bookingIds}
          programId={bookingsToMove?.programIds[0]}
          open={size(bookingsToMove?.bookingIds) > 0}
          onClose={() => setBookingsToMove(undefined)}
          afterMove={() => {
            setBookingsToMove(undefined);
            if (gridRef.current) {
              //Refresh grid and clear selection
              gridRef.current.loadDeb({ selectedIds: [] });
            }
          }}
        />
      )}
    </>
  );
}
