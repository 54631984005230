import api from "app/api";
import { Options } from "common";

export interface PartnerCommissionItem {
  id: number;
  partner_id: number;
  headcount: number;
  commission_rate: number;
}

export type PartnerCommissionOptions = Options<"role" | "status">;

export function getPartnerCommissionService(scope?: any) {
  return api.getService<
    PartnerCommissionItem,
    PartnerCommissionItem,
    PartnerCommissionOptions
  >("partner-commissions", {
    scope,
    //params goes here
  });
}
