import { Box } from "@mui/material";
import { useDialog } from "common";
import { ParamsContext } from "common/components/Form/ParamsContext";
import Dialog from "components/DraggableDialog/DraggableDialog";
import { BookingItem } from "features/bookings/bookingApi";
import { useEffect } from "react";
import BookingForm from "../../bookings/BookingForm";
import { EventItem } from "../eventApi";
import { EVENT_STATUSES } from "../eventConstants";
import EventForm from "./EventCalendarForm";

export function openCalendarModal(modal: "event" | "booking", item: any) {
  let event = new CustomEvent("openCalendarModal", {
    detail: {
      modal,
      item,
    },
  });
  document.dispatchEvent(event);
}

export function openCalendarEventModal(item?: EventItem) {
  openCalendarModal("event", item);
}
export function openCalendarBookingModal(
  event?: EventItem,
  item?: Partial<BookingItem>
) {
  openCalendarModal("booking", {
    event_id: event?.id,
    event,
    status: EVENT_STATUSES.ACTIVE,
    ...item,
  });
}

export default function EventCalendarModals({ cal }: { cal: any }) {
  const [event, setEvent, eventDialog] = useDialog<Partial<EventItem>>();
  const [booking, setBooking, bookingDialog] =
    useDialog<Partial<BookingItem>>();

  function handleOpenModal({
    detail: { modal, item },
  }: {
    detail: { modal: "booking" | "event"; item: any };
  }) {
    if (modal === "booking") {
      setBooking(item);
    }
    if (modal === "event") {
      setEvent(item);
    }
  }
  useEffect(() => {
    //@ts-ignore
    document.addEventListener("openCalendarModal", handleOpenModal);
    return () =>
      //@ts-ignore
      document.removeEventListener("openCalendarModal", handleOpenModal);
  }, []);
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        maxHeight: "calc(100vh - 84px)",
        /*"& .CellContainer:first-of-type .HOURS": {
          display: "block",
        },*/
      }}
    >
      <Dialog {...eventDialog} maxWidth="lg" fullWidth>
        <EventForm
          id={event?.id ? String(event?.id) : "create"}
          setItem={setEvent}
          defaultValues={event}
          onClose={() =>
            eventDialog.onClose && eventDialog.onClose({}, "backdropClick")
          }
          afterSave={() => {
            eventDialog.onClose && eventDialog.onClose({}, "backdropClick");
            cal.current?.refresh();
          }}
        />
      </Dialog>
      <Dialog
        {...bookingDialog}
        maxWidth={
          booking?.event?.program?.has_options ||
          booking?.planned_event?.program?.has_options
            ? "lg"
            : "sm"
        }
        fullWidth
      >
        <ParamsContext.Provider
          value={{
            ...booking,
            event_id: booking?.event_id,
            program_id: booking?.event?.program_id,
            schedule_id: booking?.event?.schedule_id,
          }}
        >
          <BookingForm
            id={booking?.id ? String(booking?.id) : "create"}
            setItem={setBooking}
            defaultValues={booking}
            onClose={() =>
              bookingDialog.onClose &&
              bookingDialog.onClose({}, "backdropClick")
            }
            afterSave={() => {
              bookingDialog.onClose &&
                bookingDialog.onClose({}, "backdropClick");
              cal.current?.refresh();
            }}
          />
        </ParamsContext.Provider>
      </Dialog>
    </Box>
  );
}
