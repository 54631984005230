import { DialogActions, DialogContent, DialogProps } from "@mui/material";
import { Button, DialogTitle, Spacer } from "common";
import Dialog from "components/DraggableDialog/DraggableDialog";
import { useEffect, useMemo, useState } from "react";
import TableSelector, { TableSelectorProps } from "./TableSelector";

const summaryColors = {
  option: "lightblue",
  variant: "lightgreen",
};
export interface TableSelectorModalProps
  extends TableSelectorProps,
    Omit<DialogProps, "onChange"> {
  guestCount?: number | null;
  validateTableCount?: boolean;
  onRemove?: (close: () => void) => void;
}
function TableSelectorModal({
  value = [],
  reserved = [],
  onChange,
  layouts = [],
  guestCount,
  validateTableCount,
  onRemove,
  ...dialogProps
}: TableSelectorModalProps) {
  const [innerValue, setInnerValue] = useState<string[] | null>(value);

  const currentGuestCount = useMemo(() => {
    let sum = 0;
    layouts.forEach(layout => {
      const tbls = layout.layout.filter(
        t => !!innerValue?.find(v => String(v) === String(t.name))
      );

      tbls.forEach(tbl => {
        sum += tbl.max_person || 0;
      });
    });
    return sum;
  }, [layouts, innerValue]);

  useEffect(() => {
    if (JSON.stringify(value || []) !== JSON.stringify(innerValue || [])) {
      setInnerValue(value);
    }
  }, [value]);
  return (
    <Dialog {...dialogProps}>
      <DialogTitle onClose={dialogProps.onClose}>
        Válassz asztalt {currentGuestCount} / {guestCount} főre
      </DialogTitle>
      <DialogContent>
        <TableSelector
          layouts={layouts}
          value={innerValue}
          reserved={reserved}
          onChange={tableIds => {
            setInnerValue(tableIds);
          }}
        />
      </DialogContent>
      <DialogActions>
        {!!onRemove && (
          <Button
            title="Vendég érkeztetés törlése"
            color="error"
            onClick={() => {
              onRemove(
                () =>
                  dialogProps.onClose &&
                  dialogProps.onClose({}, "backdropClick")
              );
            }}
          />
        )}
        <Spacer right />
        <Button
          title="Mégse"
          onClick={() =>
            dialogProps.onClose && dialogProps.onClose({}, "backdropClick")
          }
        />
        <Button
          title="Regisztrál"
          onClick={() => {
            onChange && onChange(innerValue);
            dialogProps.onClose && dialogProps.onClose({}, "backdropClick");
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

export default TableSelectorModal;
