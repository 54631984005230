import axios, { AxiosRequestConfig } from "axios";
//import { setupCache } from "axios-cache-adapter";
import { Settings } from "common/types";
import I18n from "i18n";
import { get } from "lodash";
import { globalMessageHandler } from "./../../components/GlobalMessaging";
import { Options } from "./../../modules/helpers";
import createApiService, {
  ApiConfig,
  Service,
  ServiceConfig,
} from "./createApiService";

declare module "axios" {
  export interface AxiosInstance {
    getService<T, L = any, O extends Options = Options>(
      url: string,
      config?: ServiceConfig
    ): Service<T, L, O>;
  }
  export interface AxiosRequestConfig {
    public?: boolean;
  }
  export interface AxiosResponse<T = any, D = any> {
    data: T;
    error?: any;
    success: boolean;
    status: number;
    statusText: string;
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
    config: InternalAxiosRequestConfig<D>;
    request?: any;
  }
}

/*export interface ApiRequestConfig extends AxiosRequestConfig {
  store: any;
}*/

export default function createApi(
  getStore: Function,
  settings: Settings,
  getTokenRefreshAction: Function,
  config: AxiosRequestConfig,
  apiConfig?: ApiConfig
) {
  const api = axios.create({
    ...config,
    // adapter: cache.adapter,
  });

  //Put Bearer token and refresh if needed
  api.interceptors.request.use(async config => {
    /*console.log(
      config.method,
      config.url,
      config.cache?.ignoreCache === false ? "CACHED" : ""
    );
    config.data && 
    config.params && */
    //Do nothing when request dont need authorization
    if (config.public) {
      return config;
    }

    const store = getStore();
    const state = store.getState();
    let access_token = get(state, "identity.data.access_token");

    if (
      config.url !== settings.refreshTokenUrl &&
      config.url !== settings.logoutUrl
    ) {
      const refreshToken = getTokenRefreshAction();
      const response = await store.dispatch(refreshToken());

      const new_access_token = get(response, "payload.access_token");
      if (new_access_token) {
        access_token = new_access_token;
      }
    }

    if (config.headers && access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }

    if (apiConfig?.headers) {
      const customHeaders = apiConfig?.headers(state);
      if (customHeaders) {
        config.headers = { ...config.headers, ...customHeaders } as any;
      }
    }

    return config;
  });

  //Error type

  //Process response and retun a custom structure
  api.interceptors.response.use(
    response => {
      return {
        data: response.data,
        success: true,
        request: response.request,
      } as any;
    },
    async error => {
      const url = get(error, "response.config.url");
      let status = get(error, "response.status", 0);

      if (error.message === "canceled") {
        return { success: false /*, error: message*/ };
      }
      //Auth error
      if (status === 401) {
        if (url === settings.loginUrl) {
          //Login action, its just a form validation error so retuns
          return { error: get(error, "response.data.message"), success: false };
        } else {
          //Force logout the user
          await getStore().dispatch({ type: "identity/logout/fulfilled" });
          globalMessageHandler.snack({
            severity: "error",
            message: I18n.t("App.tokenExpired"),
          });
          return { success: false };
        }
      } else if (status === 422) {
        //Validation error
        return { error: get(error, "response.data"), success: false };
      } else if (status === 406) {
        const messages = get(error, "response.data.messages", []);
        let promise = new Promise(function (resolve, reject) {
          globalMessageHandler.confirm(
            messages,
            () => {
              resolve(true);
            },
            () => {
              resolve(false);
            }
          );
        });
        const confirmResult = await promise;
        if (confirmResult) {
          error.config.headers["X-Warnings"] = "confirmed";
          return api.request(error.config);
        } else {
          return {
            success: false,
          };
        }
      }

      //Other Error show global modal

      const message = get(
        error,
        "response.data.message",
        get(error, "message", I18n.t("App.unknownServerError"))
      );

      globalMessageHandler.alert({
        severity: "error",
        message,
      });
      if (apiConfig?.axiosFatalErrorHandler) {
        apiConfig.axiosFatalErrorHandler(error);
      }
      return { success: false /*, error: message*/ };
    }
  );
  api.getService = createApiService(api, getStore, apiConfig);
  return api;
}
