import AppProvider from "AppProvider";
import "moment/locale/hu";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
declare global {
  interface Window {
    __GLOBAL_HEADER__: boolean;
  }
}
window.__GLOBAL_HEADER__ = false;
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  //<React.StrictMode>
  <AppProvider>
    <App />
  </AppProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

