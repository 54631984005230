import {
  GridModalFormProps,
  UseFormReturnType,
  Validator,
  globalMessageHandler,
  loadMultipleOptions,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { getPartnerGroupService } from "features/partner-groups/partnerGroupApi";
import I18n from "i18n";
import { Params } from "react-router-dom";
import { PartnerItem, PartnerOptions, getPartnerService } from "./partnerApi";

export interface UsePartnerFormProps extends GridModalFormProps {}

type RouteParams = {
  partner_id?: string;
};

export interface UsePartnerFormReturnType
  extends UseFormReturnType<PartnerItem, PartnerOptions> {
  params?: Readonly<
    Params<
      "partner_id" | "partner_group_id" | "booking_id" | "booking_option_id"
    >
  >;
}

export default function usePartnerForm({
  id,
  afterSave,
  defaultValues,
}: UsePartnerFormProps): UsePartnerFormReturnType {
  const params = useParams<
    "partner_id" | "partner_group_id" | "booking_id" | "booking_option_id"
  >();
  const scope = { "partner-groups": params.partner_group_id };
  const service = getPartnerService(scope);

  const form = useForm<PartnerItem, PartnerOptions>({
    id: params.partner_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "Partner",
    rules: {
      name: Validator.rule.string().required(),
      email: Validator.rule.string().required(),
      phone: Validator.rule.string().required(),
      partner_group_id: params.partner_group_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: loadMultipleOptions({
      partner_group_id: () =>
        getPartnerGroupService(undefined).loadAutocomplete(),
    }),
  });

  return { ...form, params };
}
