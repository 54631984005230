import { ROLES } from "app/constants";
import FilterProvider from "components/FilterProvider";
import BoatList from "features/boats/BoatList";
import BookingList from "features/bookings/BookingList";
import CateringBookingList from "features/catering/CateringBookingList";
import CateringPage from "features/catering/CateringPage";
import EventList from "features/events/EventList";
import ExchangeRateForm from "features/exchange-rates/ExchangeRateForm";
import ExchangeRateList from "features/exchange-rates/ExchangeRateList";
import FileList from "features/files/FileList";
import HistoryList from "features/histories/HistoryList";
import KitchenBookingList from "features/kitchen/KitchenBookingList";
import KitchenPage from "features/kitchen/KitchenPage";
import OptionGroupList from "features/option-groups/OptionGroupList";
import PartnerGroupList from "features/partner-groups/PartnerGroupList";
import PartnerList from "features/partners/PartnerList";
import PermissionForm from "features/permissions/PermissionForm";
import ProfileForm from "features/profile/ProfileForm";
import useAccess from "features/profile/useAccess";
import ProgramForm from "features/programs/ProgramForm";
import ProgramList from "features/programs/ProgramList";
import AdvancedReport from "features/reports/AdvancedReport/AdvancedReport";
import RoleForm from "features/roles/RoleForm";
import RoleList from "features/roles/RoleList";
import RoleTabs from "features/roles/RoleTabs";
import ScheduleForm from "features/schedules/ScheduleForm";
import ScheduleList from "features/schedules/ScheduleList";
import ScheduleTabs from "features/schedules/ScheduleTabs";
import SeatingLayoutList from "features/seating-layouts/SeatingLayoutList";
import StreetSalesPage from "features/street-sales/StreetSalesPage";
import UserForm from "features/users/UserForm";
import UserList from "features/users/UserList";
import UserTabs from "features/users/UserTabs";
import AdminLayout from "layouts/Admin/AdminLayout";
import Home from "pages/home/Home";
import PageNotFound from "pages/page-not-found/PageNotFound";
import { Navigate, Route, Routes } from "react-router-dom";

export default function AdminRouter() {
  const { hasRoles, notHasRoles } = useAccess();
  let initialPath = "/";
  if (hasRoles(ROLES.HEAD_OFFICE)) {
    initialPath = "/reports/advanced";
  } else if (hasRoles(ROLES.CATERING)) {
    initialPath = "catering";
  } else if (hasRoles(ROLES.STREET_SALES)) {
    initialPath = "/street-sales";
  } else if (hasRoles(ROLES.KITCHEN)) {
    initialPath = "/kitchen";
  }

  return (
    <Routes>
      <Route path="/login" element={<Navigate to={initialPath} />} />
      <Route path="/" element={<AdminLayout />}>
        {notHasRoles(ROLES.STREET_SALES) && (
          <Route path="" element={<Home />} />
        )}
        <Route path="kitchen" element={<KitchenPage />}>
          <Route path=":start/:boat_id" element={<KitchenBookingList />} />
        </Route>
        {hasRoles([ROLES.ADMIN, ROLES.SUPERADMIN, ROLES.HEAD_OFFICE]) && (
          <Route path="reports">
            <Route path="advanced" element={<AdvancedReport />} />
          </Route>
        )}
        <Route path="catering" element={<CateringPage />}>
          <Route path=":start/:boat_id" element={<CateringBookingList />} />
        </Route>
        <Route path="street-sales" element={<StreetSalesPage />} />
        <Route path="users">
          <Route path="" element={<UserList />} />
          <Route path=":user_id" element={<UserTabs />}>
            <Route path="permissions" element={<PermissionForm />} />
            <Route path="" element={<UserForm />} />
          </Route>
        </Route>
        <Route path="seating-layouts" element={<SeatingLayoutList />} />
        <Route path="partner-groups" element={<PartnerGroupList />} />
        <Route path="partners" element={<PartnerList />} />
        <Route path="programs">
          <Route path="" element={<ProgramList />} />
          <Route path=":program_id" element={<ProgramForm />} />
        </Route>
        <Route path="option-groups" element={<OptionGroupList />} />
        <Route path="boats" element={<BoatList />} />
        <Route path="schedules">
          <Route path="" element={<ScheduleList />} />
          <Route path=":schedule_id" element={<ScheduleTabs />}>
            <Route path="" element={<ScheduleForm />} />
            <Route
              path="events"
              element={<EventList id="schedule.events" showHeader={false} />}
            />
          </Route>
        </Route>
        <Route
          path="events"
          element={
            <FilterProvider id="events.main" storageType="session">
              <EventList id="main" />
            </FilterProvider>
          }
        />
        <Route
          path="bookings"
          element={
            <FilterProvider id="bookings.main" storageType="session">
              <BookingList id="main" />
            </FilterProvider>
          }
        />
        <Route path="exchange-rates">
          <Route path="" element={<ExchangeRateList />} />
          <Route path=":exchange_rate_id" element={<ExchangeRateForm />} />
        </Route>
        <Route path="history" element={<HistoryList />} />
        <Route path="roles">
          <Route path="" element={<RoleList />} />
          <Route path=":role_id" element={<RoleTabs />}>
            <Route path="" element={<RoleForm />} />
            <Route path="permissions" element={<PermissionForm />} />
          </Route>
        </Route>
        <Route path="files" element={<FileList />} />
        <Route path="profile" element={<ProfileForm />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}
