import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Dialog from "components/DraggableDialog/DraggableDialog";
import I18n from "i18n";
import SessionTimer from "./SessionTimer";
import useSessionHandler, { UseSessionHandlerProps } from "./useSessionHandler";

export interface SessionHandlerProps extends UseSessionHandlerProps {}

export default function SessionHandler({
  tokenExpiresAt,
  logout,
  refreshToken,
  time,
}: SessionHandlerProps) {
  const { open, handleLogout, handleRefresh } = useSessionHandler({
    tokenExpiresAt,
    logout,
    refreshToken,
    time,
  });

  return (
    <Dialog open={open}>
      <DialogTitle>{I18n.t("SessionHandler.title")}</DialogTitle>
      <DialogContent>
        {I18n.t("SessionHandler.description")}
        <SessionTimer tokenExpiresAt={tokenExpiresAt} />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => handleLogout()}>
          {I18n.t("SessionHandler.logoutAction")}
        </Button>
        <Button color="primary" onClick={() => handleRefresh()}>
          {I18n.t("SessionHandler.stayLoggedInAction")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
